/** @format */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap');
$main: #2181fa;
$main-bg: linear-gradient(90deg, #e95567 0%, #f36735 100%);
$card-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;

@media only screen and (max-width: 1920px) {
  .black-bar-safari {
    width: 79%;
  }
}

@media only screen and (max-width: 1366px) {
  .black-bar-safari {
    width: 72%;
  }
}

@media only screen and (max-width: 800px) {
  .black-bar-safari {
    width: 58%;
    left: 59px;
  }
}

@media only screen and (max-width: 900px) {
  .black-bar-safari {
    width: 58%;
    left: 59px;
  }
}

@media only screen and (max-width: 1300px) {
  .hero-section .content {
    padding-top: 200px;
    margin-left: 16%;
  }
}

@media only screen and (max-width: 425px) {
  .search-bar {
    display: none !important;
  }

  .Login_Page {
    .card {
      padding: 20px 10px 50px 10px;
    }
  }

  .guZdik {
    min-width: fit-content !important;
  }

  .studio-main-body,
  .Report-Tabs {
    padding: 25px;
  }
}

@media only screen and (max-width: 768px) {
  .red-bar {
    left: 5px;
  }
  .black-bar {
    left: 5px;
  }
  .Play {
    #videosection {
      .channel_details {
        .control-buttons {
          top: 10%;
        }
      }
    }
  }

  .assignClass_btn button {
    width: 100%;
  }

  .auth-body .auth-form .signup-btns {
    width: 100%;
  }

  .content-modal .tabs-heading {
    padding: 0px 15px;
  }

  #listner-div .nav-item {
    margin-bottom: 0px !important;
    // padding: 0px 10px !important;
    height: auto !important;

    .nav-link {
      padding: 10px 0px !important;
      height: auto !important;
      border-radius: 0px;
    }

    .nav-link.active {
      font-weight: 700 !important;
    }
  }

  .content-modal .library-tabs .nav-tabs {
    flex-wrap: nowrap;

    .nav-item {
      width: 50%;
    }
  }

  .content-modal .library-tabs {
    padding: 15px;
  }

  .search-bar .react-datalist-input__container input {
    width: 120px;
  }

  .cole-book-list-card.header {
    display: none;
  }

  .cole-book-list-card {
    padding: 15px !important;
    // flex-wrap: wrap;
    flex-direction: column;
    position: relative;

    .book-detail {
      margin-bottom: 5px;
      width: 100% !important;
    }

    .book-detail:nth-child(1) {
      width: 20px !important;
      position: absolute;
      top: 15px;
      right: 15px;
    }

    .book-detail:nth-child(2) {
      width: 100% !important;
    }

    .dropdown {
      width: 20px !important;
      position: absolute;
      bottom: 10px;
      right: 15px;
    }
  }

  .auth-header .right-nav .nav-link i {
    margin: 5px 10px 0px 0px;
    font-size: 18px;
  }

  .auth-header {
    padding: 0px 15px;

    .logo img {
      height: 30px;
    }

    .left-nav .nav-link {
      display: none;
    }
  }

  .body-content {
    h5,
    h6,
    h3 {
      margin-bottom: 15px !important;
    }
  }

  .access-box {
    padding: 15px 0px;
  }

  .dashboard-body {
    padding: 30px 15px;

    .body-content .add-btns {
      flex-direction: column;
      margin-bottom: 20px;

      .btn {
        margin-right: 0px;
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }

  .auth-body .auth-form .fav {
    height: 60px;
  }

  .header-row {
    width: 100%;
    display: flex;
    // justify-content: center;
  }

  .auth-body .auth-form {
    width: 100%;
    padding: 0px 20px;
  }

  .auth-body .auth-form small {
    font-size: 14px;
  }

  .auth-body .auth-form h2 {
    font-size: 24px;
  }

  .auth-body .auth-form h6 {
    font-size: 16px;
  }

  .auth-body h5 {
    font-size: 24px;
  }

  .auth-body .auth-form .field-row {
    width: 100%;
  }

  .auth-body .network-url {
    width: 100%;
  }

  .auth-body .devider {
    padding: 30px 0px 20px;
  }

  .auth-body .devider .xyz {
    width: 300px;
  }

  .auth-body .auth-form .mail-img {
    height: 80px;
  }

  .auth-body .auth-form h6 {
    font-size: 14px;
  }

  .f-container {
    overflow-x: auto !important;
    overflow-y: visible !important;

    .collection-outer-body {
      width: 100vh;
    }
  }

  .Footer {
    text-align: center;

    .Footer_inner {
      padding: 12px;
    }

    .flex-box-three {
      padding-top: 12px !important;
      justify-content: center;
    }
  }
}

// @media only screen and (max-width: 388px) {

//   .search-bar .react-datalist-input__container input {
//     display: none;
//   }
//   .fa-magnifying-glass:before, .fa-search:before {
//     content: "";
//     display: none;
// }
// }
// @media only screen and (max-width: 768px)
// .top-nav-section-outer {
//     height: 60px !important;
//     min-height: 40px !important;
// }

@media only screen and (max-width: 992px) {
  .scroller-user-card {
    min-width: 300px !important;
  }
  .Topbar {
    .bg-body-tertiary {
      .flex-box {
        max-width: 1000px;
        width: 100%;
        margin: 20px 0px 0px 0px;
      }
    }
  }

  .form_flex {
    flex-direction: column;
    margin: 70px 0px 100px 0px;

    .card {
      width: 100% !important;
      margin: auto;
      padding: 40px;
      border: 0.06rem solid #c8c8d5;
      min-height: 200px;

      // background-color: #000;
      h3 {
        color: #000;
        // color: #ffffff;
        font-size: 24px;
      }

      p {
        // color: #fff;
        font-size: 14px;
        margin-bottom: 30px;
      }

      .uploader-drag-drop {
        .guZdik {
          border: 1px solid #ffffff;
          background-color: #ffffff;
          border-radius: 8px;
          margin-top: 0px;

          .kFhUBM {
            color: #fff !important;
            font-size: 10px;
          }

          .jWkLDY .file-types {
            color: #fff !important;
            font-size: 10px;
          }
        }
      }
    }
  }

  .App_flex_box {
    display: block;

    .Tabs {
      padding: 30px 20px 30px 20px;
    }
  }

  .Play {
    width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .Play {
    #videosection {
      .channel_details {
        .control-buttons {
          // position: static;
          flex-direction: row;
          flex-wrap: wrap;
          .btn {
            // display: none;
          }
          .voice-btn {
            // display: block !important;
          }
        }
        .name-btn-flex {
          .name {
            width: 60%;
          }
        }
      }
    }
  }

  // Topbar
  .Topbar {
    .bg-body-tertiary {
      padding: 10px;
      .flex-box {
        margin-top: 20px;
        max-width: 700px;
        width: 100%;
      }
      .Responsive-flex {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  .Tabs {
    .Users-Tabs {
      .nav-link:hover {
        border: none;
      }

      .nav-fill .nav-item .nav-link,
      .nav-justified .nav-item .nav-link {
        font-size: 20px;
      }

      .nav-justified .nav-item,
      .nav-justified > .nav-link {
        flex-grow: 0;
        width: 20%;
        flex-basis: content;
      }

      .nav-tabs {
        border: none;
        gap: 10px;
      }
    }

    .Trending {
      h1 {
        font-family: 'Fira Sans Extra Condensed', sans-serif !important;
        font-size: 40px;
        font-weight: 600;
        line-height: 40px;
        text-align: left;
        color: #000;
        margin: 0px 0px 60px 0px;
      }
    }

    .flex-box-five {
      display: flex;
      padding-bottom: 20px;
      text-decoration: none;
      color: #202020;
      width: fit-content;

      .profile-img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
      }

      .channel-details {
        padding-left: 10px;
        height: 100%;

        .name {
          font-size: 16px;
          font-weight: 600;
        }

        .subscribers {
          padding-top: 20px;
          font-size: 12px;
        }
      }
    }

    /* width */
    .css-heg063-MuiTabs-flexContainer::-webkit-scrollbar {
      display: none;
    }

    .css-heg063-MuiTabs-flexContainer {
      overflow-x: auto;
    }

    .css-13xfq8m-MuiTabPanel-root {
      padding: 24px 10px;
    }

    .css-19kzrtu {
      padding: 24px 10px;
    }
  }

  .content-body {
    padding: 20px 10px;
    overflow: hidden;
  }

  .add-utube-vimeo {
    .btn-danger {
      width: 70px !important;
    }
  }

  .library-tabs .form_flex .card {
    padding: 40px 20px;

    .jWkLDY .file-types {
      display: none;
    }

    .jWkLDY > span {
      font-size: 10px;
    }

    .guZdik,
    .dtlKip {
      min-width: fit-content !important;
      padding: 8px 8px 8px 8px;
      width: 100%;
    }
  }
}
