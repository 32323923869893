/** @format */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://site-assets.fontawesome.com/releases/v6.4.0/css/all.css');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

$main: #2181fa;
$border-light: #cccccc;
$primary: #4286f5;
$white: #fff;
$bg-light: #fbfbfb;
$lightgray: #f0f0f0;
$black: #202223;
$box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
$border: #8c9196;
$border-light: #cccccc;
$manrope: 'Manrope', sans-serif;

h1,
h2,
h3,
h4,
h5,
h6,
b,
small,
p,
span,
a,
.nav-link,
.nav-item,
.navbar-nav,
input {
  // font-family: Poppins !important;
  font-family: "Montserrat", sans-serif !important;
}

input,
textarea,
button,
.btn {
  outline: 2px solid transparent !important;
  box-shadow: none !important;
}

html {
  --scrollbarBG: #cfd8dc;
  --thumbBG: #90a4ae;
}

html,
body {
  transition: all ease-in-out 0.4s;
  scroll-behavior: smooth;
}

body {
  font-family: "Montserrat", sans-serif !important;
  overflow-x: hidden;
  padding-bottom: 60px;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  background-color: #f0f0f1 !important;
}

p {
  margin-bottom: 0px;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 50px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

img {
  -webkit-user-drag: none;
}

.flex-app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.animatedup {
  animation: up-down 1.5s ease-in-out infinite alternate-reverse both;
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

.animatedleft {
  animation: left-right 1s ease-in-out infinite alternate-reverse both;
}

@keyframes left-right {
  0% {
    transform: translateX(20px);
  }

  100% {
    transform: translateX(-20px);
  }
}

.toast {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10000;
  background-color: #28a745;
  color: white;
}

.toast-header {
  background-color: #218838;
  color: white;
}

#listner-div {
  width: 100%;
  display: flex !important;

  .content-body {
    position: relative;
    width: 100%;
  }
}

.content-modal {
  .tabs-heading {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border-bottom: 1px solid #cccccc;

    h4 {
      margin-bottom: 0px;
      margin-left: 60px;
    }
  }
}

.studio-main-body {
  padding: 25px 50px;
}

// Libarary Header
.assets-header-section {
  width: 100%;

  .assets-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    h3 {
      color: var(--Primary-text, #2d2f31);
      font-family: "Montserrat", sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 0px;
    }

    .btn {
      height: 44px;
      width: 44px;
      padding: 0px;

      i {
        color: #000;
        font-size: 20px;
        margin-top: 5px;
      }
    }

    .options {
      display: flex;
      align-items: center;

      .dropdown-toggle::after {
        display: none;
      }
    }

    .btn-light {
      margin-left: 10px !important;
      border-radius: 4px !important;
      border: 1px solid var(--line, #dee1e6) !important;
      background: var(--White, #fff) !important;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1) !important;
    }
  }

  .assets-top-filter {
    margin-bottom: 30px;

    .search-assets {
      position: relative;

      svg,
      .fa-magnifying-glass {
        position: absolute;
        left: 18px;
        top: 12px;
        color: #999999;
      }

      input {
        padding-left: 50px;
        height: 40px;
        width: 100%;
        border-radius: 4px;
        border: 1px solid var(--line, #dee1e6);
        background: var(--White, #fff);
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1) !important;
      }

      input::placeholder {
        color: var(--text-2, #a29e9e);
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .timer {
      height: 40px;
      width: 100%;
      border-radius: 4px;
      border: 1px solid var(--line, #dee1e6);
      background: var(--White, #fff);
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;

      .dropdown {
        width: 35%;
      }

      .dropdown-toggle {
        height: 38px;
        width: 100%;
        text-decoration: none;
        color: var(--Primary-text, #2d2f31);
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .dropdown-toggle::after {
        margin-left: 12px;
      }

      .date {
        width: 65%;
        border-left: 1px solid #dee1e6;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 10px;

        span {
          color: var(--Primary-text, #2d2f31);
          font-family: "Montserrat", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }

        svg {
          margin-right: 10px;
        }
      }
    }
  }
}

.Report-Tabs {
  padding: 25px 50px;

  .assets-table-body .table-row .content_box {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 31%;
  }
}

// Report Library Table header
.assets-table-head {
  width: 100%;
  padding: 12px 25px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--line, #dee1e6);
  background: #fff;

  .col-lg-8 .table-data:last-child {
    justify-content: flex-end;
  }

  .table-row {
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .fa-arrow-down {
      margin-left: 15px;
      color: #dee1e6;
      font-size: 14px;
    }

    .table-data {
      width: 100%;
      display: flex;
      align-items: center;

      .fa-star {
        color: #dee1e6;
        font-size: 18px;
      }

      p {
        color: var(--Primary-text, #2d2f31);
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 0px;
      }
    }
  }
}

// Report Library Table Body
.assets-table-body {
  width: 100%;
  padding: 12px 25px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid #dee1e6;
  // background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  &:hover{
    box-shadow: 0 8px 10px rgba(0,0,0,0.15);
  }

  .table-data{
    flex-direction: column;
    gap: 10px;
    >.btn-image{
      >.img_cards{
        border-radius: 50px !important;
        width: 45px !important;
        height: 45px !important;
        >img{
          border-radius: 50px !important;
          width: 45px !important;
          height: 45px !important;
        }
      }
    }
  }

  .col-lg-9 .table-data:last-child {
    justify-content: flex-end;
  }

  .reaction-btns {
    align-items: center;
    background-color: #0000;
    color: #000;
    display: flex;
    font-family: Roboto, Helvetica, Arial, sans-serif !important;
    font-size: 12px;
    font-weight: 700;
    width: 100%;
    justify-content: space-between;
    gap: 5px;


    .Likes-btn {
      background-color: transparent;
      border: none !important;
      color: #ff0000;
      font-size: 1.2rem !important;
      padding-left: 0px;
      padding-right: 3px;

      .css-i4bv87-MuiSvgIcon-root {
        font-size: 1.2rem !important;
      }
    }

    .css-i4bv87-MuiSvgIcon-root {
      font-size: 1.2rem !important;
    }

    .clap-btn {
      color: goldenrod;
    }
  }

  .table-row {
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .table-data {
      width: 100%;
      display: flex;
      align-items: center;

      &:last-child {
        justify-content: flex-end;
      }

      .dropdown {
        border: none;
        box-shadow: none;
      }

      .dropdown-menu {
        // padding: 10px;
        box-shadow: 0px 6px 15px 5px rgba(0, 0, 0, 0.1);
        border: none;

        a {
          text-decoration: none;
          color: #000;

          .fa-add:before,
          .fa-plus:before,
          .fa-pencil-alt:before,
          .fa-pencil:before {
            width: 24px !important;
            height: 24px !important;
            margin: 0px 10px 0px 5px;
          }
        }

        .option {
          display: flex;
          flex-direction: column;
          gap: 10px;

          a {
            padding: 0px 10px 10px 10px;
            border-bottom: 1px solid #cccccc;
          }

          p {
            padding: 0px 10px;
            // margin-bottom: 0px;
          }

          svg {
            width: 24px;
            height: 24px;
            margin-right: 5px;
          }

          span {
            font-size: 14px;
            color: #000;
          }
        }
      }

      .dropdown-toggle::after {
        display: none;
      }

      .fa-star {
        color: #dee1e6;
        font-size: 18px;
      }

      .symbol svg {
        width: 36px;
        height: 36px;
        flex-shrink: 0;
        fill: var(--White, #fff);
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25));
      }

      .btn-image {
        border: none;
        background-color: transparent;
        padding: 0px;

        img {
          height: 36px;
          width: 36px;
          border-radius: 4px;
          margin-right: 10px;
        }
      }

      .popup-image {
        display: none;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 300px; // Ensure the container has a width
        height: 300px; // Ensure the container has a height
      }

      .btn-image:hover+.popup-image,
      .popup-image:hover {
        display: block;
      }

      .popup-image video {
        width: 100%;
        height: 100%;
        max-width: 300px; // Optional: adjust as needed
        max-height: 300px; // Optional: adjust as needed
        position: relative; // Ensure video is positioned correctly within the container
      }

      h3 {
        margin-bottom: 0px;
        color: var(--On-Surface-Text-Colour, #202223);
        font-family: "Montserrat", sans-serif !important;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }

      p {
        margin-bottom: 0px;
        color: var(--On-Surface-Text-Colour, #202223);
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        display: flex;
        align-items: center;

        svg {
          margin-right: 8px;
        }
      }
    }
  }
}

.assets-table-body-drive {
  margin: auto;
  width: 100%;
  padding: 12px 25px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid var(--line, #dee1e6);
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  .col-lg-9 .table-data:last-child {
    justify-content: flex-end;
  }

  .reaction-btns {
    align-items: center;
    background-color: #0000;
    color: #000;
    display: flex;
    font-family: Roboto, Helvetica, Arial, sans-serif !important;
    font-size: 14px;
    font-weight: 700;
    width: 100%;
    justify-content: end;
    // gap: 10px;

    .Likes-btn {
      background-color: transparent;
      border: none !important;
      color: #ff0000;
      font-size: 1rem !important;
      padding-left: 0px;
      padding-right: 3px;
    }

    .clap-btn {
      color: goldenrod;
    }
  }

  .table-row {
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .table-data {
      width: 100%;
      display: flex;
      align-items: center;

      .drive-inputs {
        width: fit-content !important;
      }

      &:last-child {
        justify-content: flex-end;
      }

      .dropdown {
        border: none;
        box-shadow: none;
      }

      .dropdown-menu {
        // padding: 10px;
        box-shadow: 0px 6px 15px 5px rgba(0, 0, 0, 0.1);
        border: none;

        a {
          text-decoration: none;
          color: #000;

          .fa-add:before,
          .fa-plus:before,
          .fa-pencil-alt:before,
          .fa-pencil:before {
            width: 24px !important;
            height: 24px !important;
            margin: 0px 10px 0px 5px;
          }
        }

        .option {
          display: flex;
          flex-direction: column;
          gap: 10px;

          a {
            padding: 0px 10px 10px 10px;
            border-bottom: 1px solid #cccccc;
          }

          p {
            padding: 0px 10px;
            // margin-bottom: 0px;
          }

          svg {
            width: 24px;
            height: 24px;
            margin-right: 5px;
          }

          span {
            font-size: 14px;
            color: #000;
          }
        }
      }

      .dropdown-toggle::after {
        display: none;
      }

      .fa-star {
        color: #dee1e6;
        font-size: 18px;
      }

      .symbol svg {
        width: 36px;
        height: 36px;
        flex-shrink: 0;
        fill: var(--White, #fff);
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25));
      }

      img {
        height: 36px;
        width: 36px;
        border-radius: 4px;
        margin-right: 10px;
      }

      h3 {
        margin-bottom: 0px;
        color: var(--On-Surface-Text-Colour, #202223);
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }

      p {
        margin-bottom: 0px;
        color: var(--On-Surface-Text-Colour, #202223);
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        display: flex;
        align-items: center;

        svg {
          margin-right: 8px;
        }
      }
    }
  }
}

.App_flex_box {
  display: flex;
  overflow-x: hidden;
}

.btn-danger {
  // background-color: rgba(217, 37, 29, 1.0) !important;
  // background: linear-gradient(124deg, #CD9A27 50%, #be780e 64%) !important;
  background-color: #c07f00 !important;
  border: none !important;
  color: #fff !important;
  font-size: 14px !important;
}

h2 {
  font-weight: 600 !important;
  font-size: 24px !important;
  color: #383838 !important;
}

// Navbar
.Topbar {
  // position: sticky;
  // top: 0px;
  z-index: 1 !important;
  // margin: 15px 0px 30px 0px;

  .bg-body-tertiary {
    padding: 10px 20px;
    background-color: #ffffff !important;
    box-shadow: 0px 18px 40px -30px #23263a36;
    border-radius: 10px;
    min-height: 70px;

    .Responsive-flex {
      display: flex;
    }

    .Toggle-img {
      width: 40px;
      margin-right: 10px;
    }

    .container-fluid {
      overflow: visible;
    }

    .navbar-toggler-icon {
      background-image: url(../public/images/toggle.png);
    }

    .Navbar-logo {
      a {
        display: flex;
        align-items: center;
        text-decoration: none !important;
      }

      p {
        margin-bottom: 0px;
        font-size: 20px;
        text-decoration: none !important;
        color: goldenrod;
        font-weight: 700;
        font-family: 'Fira Sans Extra Condensed', sans-serif !important;
        letter-spacing: 1px;
      }

      img {
        width: 80px;
        height: 40px;
      }
    }

    .Nav-link {
      color: #000000 !important;
      font-weight: 500;
      box-shadow: none;
      font-size: 16px;
      text-decoration: none;
      border: none;

      &.active {
        border: none;
        color: #757474;
        font-weight: 700;
      }
    }

    .btn-check:checked+.btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check)+.btn:active {
      border: none;
      color: #757474;
      font-weight: 700;
    }

    .flex-box {
      background-color: #ffffff;
      border: 1px solid #dfdfdf;
      color: #ffffff;
      border-radius: 50px;
      box-shadow: none;
      display: flex;
      align-items: center;
      margin: auto;
      max-width: 700px;
      width: 80%;

      .form-control {
        background-color: #ffffff;
        border: none !important;
        box-shadow: none;
        color: #757575;
        border-radius: 50px !important;
        font-size: 14px;
      }

      .form-control::placeholder {
        color: #757575;
      }

      .icon-btn {
        cursor: pointer;
        color: #ffffff;
        right: 0px;
        background: linear-gradient(90deg, #ff0000 0%, #d60000 100%);
        border: none;
        border-radius: 50px;
        width: 116px;
        margin: 1px;
      }
    }

    .Login-portion {
      display: flex;
      margin-left: auto;

      .dropdown {


        svg {}
      }

      // .btn-danger {
      //   background-color: #d9251d;
      //   border-radius: 3px;
      //   font-weight: 600;
      //   border: none;
      //   margin-right: 5px;
      //   padding: 6px 8px;
      //   font-size: 14px;
      // }

      // .btn-danger:hover {
      //   background-color: rgba(212, 212, 212, 1);
      // }

      // Dropdown
      .dropdown-toggle {
        background-color: #fff0d7;
        border-radius: 50px;
        width: 40px;
        height: 40px;
        min-width: 0px !important;
        min-height: 0px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none !important;

        svg {
          color: #f6bb19;
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }

      .dropdown-menu {
        left: -310%;
        border-radius: 3px;
        padding: 4px 0px;

        .btn {
          background-color: transparent;
          color: #757575;
          border: none;
          width: 100%;
          border-radius: 0px;
          font-size: 14px;
          box-shadow: none;

          &:nth-of-type(2) {
            border-top: 1px solid #dddada;
          }
        }
      }

      .dropdown-toggle::after {
        display: none;
      }
    }
  }
}

// offcanvas
.offcanvas.offcanvas-start {
  max-width: 220px;
  background-color: rgba(0, 0, 0, 1);

  .offcanvas-header {
    padding: 16px 0px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);

    img {
      width: 90px;
      height: 40px;
      padding-left: 20px;
    }

    .btn-close {
      padding-right: 40px;
      box-shadow: none;
      background-image: url(../public/images/Closebtn.png);
    }
  }

  .offcanvas-body {
    padding: 0px;

    .offcanvas-flex {
      display: flex;
      flex-direction: column;

      a {
        color: #fff;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        padding: 10px;

        .btn {
          font-size: 16px;
          font-weight: 600;
          border: none;

          &:hover {
            color: #757474;
          }
        }
      }
    }

    .Nav-link.active {
      border: none !important;
    }

    a:hover {
      color: #757474;
      font-weight: 700;
    }
  }
}

.Offcanvas-btn {
  background-color: transparent !important;
  border-radius: 5px;
  font-weight: 600;
  border: none !important;
  padding: 4px 8px 4px 0px !important;
  font-size: 16px;
  box-shadow: none;

  img {
    width: 25px;
    height: 22px;
  }
}

.Offcanvas-btn:hover,
.Offcanvas-btn:focus {
  background-color: transparent;
}

// Footer
.Footer {
  background-color: #000000;
  padding: 40px 20px;
  color: #ffffff;
  border-radius: 5px !important;

  .footer_icons {
    display: flex;
    align-items: center;
    gap: 10px;
    // justify-content: center;
    padding-top: 40px;

    .icons_bg {
      background-color: #f6bb1aa9;
      border-radius: 50px !important;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 25px;
        height: 25px;
        color: #fff;
      }
    }
  }

  h4 {
    font-weight: 600;
  }

  p {
    margin-bottom: 0px;
    font-size: 14px;
  }

  .img-logo {
    // display: flex;
    // justify-content: center;
    width: 120px;
    height: 60px;
    // margin: 0px auto 30px auto;
  }

  a {
    color: #ffffff;
    text-decoration: none;
    line-height: 35px;
    font-size: 14px;
  }

  input,
  textarea,
  button,
  .btn {
    font-size: 14px !important;
    color: #d1d1d1;
  }

  a:hover {
    // color: #d9251d;
    font-weight: 600;
  }

  .Footer_inner {
    padding: 30px 0px 0px 12px;
  }

  .flex-box-three {
    display: flex;
    padding-top: 30px;

    .css-i4bv87-MuiSvgIcon-root {
      padding-right: 10px;
      font-size: 40px;
    }

    .css-vubbuv {
      padding-right: 10px;
      font-size: 40px;
    }
  }
}

// Tabs
.Tabs {
  padding: 30px 10px 0px 20px;

  .Users-Tabs {

    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
      border: none;
      color: #ff0000 !important;
      border-bottom: 4px solid #ff0000 !important;
      border-radius: 0px;
      width: fit-content;
    }

    .nav-link:hover {
      border: none;
    }

    .nav-fill .nav-item .nav-link,
    .nav-justified .nav-item .nav-link {
      width: fit-content;
      font-family: 'Fira Sans Extra Condensed', sans-serif !important;
      font-size: 40px;
      font-weight: 600;
      line-height: 40px;
      text-align: left;
      color: #000;
      border: none;
    }

    .nav-justified .nav-item,
    .nav-justified>.nav-link {
      flex-grow: 0;
      width: 20%;
      flex-basis: content;
    }

    .nav-tabs {
      border: none;
      gap: 20px;
    }
  }

  .bg-gradiant {
    .content-box {
      img {
        height: 100%;
        width: 100%;
        border-radius: 20px;
      }
    }
  }

  .Trending {
    h1 {
      font-family: 'Fira Sans Extra Condensed', sans-serif !important;
      font-size: 40px;
      font-weight: 600;
      line-height: 40px;
      text-align: left;
      color: #000;
      margin: 60px 0px;
    }
  }

  /* width */
  .css-heg063-MuiTabs-flexContainer::-webkit-scrollbar {
    display: none;
  }

  .css-heg063-MuiTabs-flexContainer {
    overflow-x: auto;
  }

  .css-13xfq8m-MuiTabPanel-root {
    padding: 24px 10px;
  }

  .css-19kzrtu {
    padding: 24px 10px;
  }
}

// .Cards-box {
//   height: 100%;
//   margin-bottom: 20px;

//   .Latest-tab {
//     height: 100%;

//     .play_button {
//       text-decoration: none !important;
//     }

//     .card {
//       padding: 10px 10px 0px 10px;
//       height: 100%;
//       border: none;
//       // box-shadow: 0 0 15px 5px rgba(132, 132, 132, 0.1);
//       box-shadow: 0 4px 15px 2px rgb(53 53 53 / 10%);

//       &:hover {
//         box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.1);
//       }

//       .img_cards {
//         min-height: 300px;
//       }

//       .Card_img {
//         position: relative;
//         // border-radius: 5px;
//         display: flex;
//         align-items: center;
//         gap: 5px;
//         max-height: 300px;

//         img {
//           // height: 150px;
//           // width: -webkit-fill-available;
//           width: 36px;
//           height: 36px;
//           border-radius: 4px;
//           // border-top-left-radius: 10px;
//           // border-top-right-radius: 10px;
//           object-fit: cover;
//           transition: transform 0.5s ease;

//           /* Add transition for smooth animation */
//           &:hover {
//             transform: scale(1.1);
//           }
//         }

//         .img-videos {
//           min-height: 300px;
//         }

//         .card-title {
//           color: #000;
//           font-size: 14px !important;
//           font-weight: 600;
//           display: -webkit-box;
//           -webkit-line-clamp: 4;
//           /* Number of lines to show */
//           -webkit-box-orient: vertical;
//           overflow: hidden;
//           width: 102%;
//           margin-bottom: 0px;
//         }

//         .play_button {
//           // background-color: rgba(34, 34, 34, 0.911);
//           border-top-left-radius: 5px;
//           border-top-right-radius: 5px;
//           color: #ffffff;
//           border: none;
//           position: absolute;
//           top: 0%;
//           left: 0%;
//           display: none;
//           padding: 10px;
//           font-size: 14px;
//           font-weight: 600px;
//           width: 100%;
//           height: 100%;
//           //   text-decoration: none;
//         }
//       }

//       .Card_img:hover .play_button {
//         display: none;
//         justify-content: center;
//         align-items: center;
//       }

//       .card_body {
//         padding: 10px 0px 0px 0px;

//         .reaction-btns {
//           align-items: center;
//           background-color: #0000;
//           color: #000;
//           display: flex;
//           font-family: Roboto, Helvetica, Arial, sans-serif !important;
//           font-size: 14px;
//           font-weight: 700;
//           margin-top: 10px;
//           width: 100%;

//           .Likes-btn {
//             background-color: transparent;
//             border: none !important;
//             color: #ff0000;
//             font-size: 1rem !important;
//             padding-left: 0px;

//             svg {
//               font-size: 20px;
//             }
//           }

//           .eye-icon {
//             color: #000;

//             svg {
//               font-size: 16px;
//             }
//           }

//           .clap-btn {
//             color: goldenrod;
//           }
//         }

//         .flex-card-body {
//           display: flex;
//           align-items: center;
//           gap: 8px;

//           img {
//             width: 40px;
//             height: 40px;
//           }
//         }

//         .card-text {
//           font-size: 13px;
//           color: #666666;
//           display: -webkit-box;
//           -webkit-line-clamp: 4;
//           -webkit-box-orient: vertical;
//           overflow: hidden;
//         }

//         .Video-details {
//           padding-top: 10px;
//           display: flex;
//           justify-content: space-between;
//           flex-wrap: wrap;

//           .views {
//             font-size: 13px;
//             color: #585858;
//             text-decoration: none;
//             font-weight: 500;
//             padding-right: 4px;

//             .css-i4bv87-MuiSvgIcon-root {
//               width: 16px;
//               height: 16px;
//             }

//             .css-vubbuv {
//               width: 16px;
//               height: 16px;
//             }
//           }

//           .views:hover {
//             color: #202020;
//           }
//         }
//       }
//     }

//     .card:hover {
//       cursor: pointer;
//     }
//   }
// }

.videos_card {
  border: 1px solid #c7c7c7a2;
  box-shadow: 0 4px 15px 2px rgba(53, 53, 53, 0.1);
  border-radius: 8px;
  background-color: #fff;
  overflow-x: hidden !important;
  position: relative;

  .heading_box{
    position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1; /* Ensure it stays on top */
   
    .heading_btn{
      position: absolute;
      top: 0px;
      right: 0px; 
      background-color: #daa52042;
      color: #daa520;
      padding: 0px 5px 5px 5px;
      font-size: 24px;
    }
    .card_content {
      // display: none;
      
      flex-direction: column;
      justify-content: space-between;
      height: fit-content;
      background-color: #0000008e !important;
      border-radius: 5px;

      h2 {
        font-family: "Montserrat" !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        color: #ffffffef !important;
        padding: 5px;
        margin-bottom: 0px !important;
        transition: opacity 0.3s ease;
      }
      .typewriter {
        overflow: hidden;
        white-space: wrap; 
        animation: typewriter 4s steps(40, end);
      }
      @keyframes typewriter {
        from { width: 0; }
        to { width: 100%; }
      }
    }
    .heading_btn:hover + .card_content {
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      right: 34px;
    }
  }

  a{
    text-decoration: none !important;
  }
  
  .videos_card_flex {

    .card_img {
      width: 100%;
      height: 300px;
      position: relative;
      overflow: hidden;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;;
        display: flex;
        align-items: center;

      img , video {
        width: 100%;
        position: absolute;
        object-fit: contain;
        max-height: 300px;
      }
    }
  }

  // .reaction_btns {
  //   display: flex;
  //   justify-content: space-between;
  //   gap: 5px;
  //   border-top: 1px solid #c7c7c7;
  //   padding: 10px 20px;
  //   font-size: 10px;
  //   color: #363636;
  //   p{
  //     margin-bottom: 0px !important;
  //   }
  //   svg{
  //     font-size: 25px;
  //     display: block;
  //   }
  // }

  .reaction_cards {
    display: flex;
    border-top: 1px solid #c7c7c7;
    padding: 20px 15px;
    position: relative;
    justify-content: space-around;

    .social-icons {
      cursor: pointer;
      display: flex;
      transition: all 0.5s;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      color: #000000de;
      font-weight: 500;
      font-size: small;

      svg {
        width: 25px;
        height: 25px;
      }

      >p {
        --var: -0%;
        position: absolute;
        top: var(--var);
        transition: all 0.7s;
        background-color: #f6bb19;
        border-radius: 7px;
        opacity: 0;
        padding-inline: 7px;
        padding-block: 3px;
        width: max-content;
        color: #fff;
        font-size: 14px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
          content: "";
          position: absolute;
          border-top: 10px solid #f6bb19;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          top: 100%;
          left: 50%;
          transform: translate(-50%);
        }
      }

      >a {
        --var: -0%;
        position: absolute;
        bottom: var(--var);
        width: max-content;
        transition: all 0.7s;
        opacity: 0;
        padding-inline: 7px;
        padding-block: 3px;
        background-color: crimson;
        border-radius: 7px;

        &::after {
          content: "";
          position: absolute;
          border-bottom: 10px solid crimson;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          bottom: 100%;
          left: 50%;
          transform: translate(-50%);
        }
      }

      &:hover>a,
      &:hover>p {
        --var: -65%;
        opacity: 1;
      }
    }

    &:hover>.social-icons:not(:hover) {
      filter: blur(3px);
      transform: scale(0.8);
    }
  }

}

// PLAY
.Play {
  padding: 20px 13px 0px 15px;
  width: 100%;

  #videosection {
    .video-size-img {
      height: 70vh;
      border: 1px solid rgba(128, 128, 128, 0.5);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: #000;

      .google-header {
        color: red;
        width: fit-content;
      }
    }

    img {
      border-radius: 5px;
      width: 100%;
    }

    .Thumbnail-img {
      width: 100px;
      height: 100px;
      // margin-left: -10px;
      object-fit: contain;
    }

    .content {
      margin: 0px;
      font-weight: 600;
      font-size: 20px;
      padding: 10px 0px;
    }

    .channel_details {
      display: flex;
      flex-direction: column;

      .logo_img {
        video {
          max-height: 80vh;
          object-fit: contain;
          border-radius: 5px;
          background-color: #000000;
        }

        iframe {
          min-height: 80vh;
          object-fit: contain;
          border-radius: 5px;
          background-color: #000000;
        }
      }

      .voice-position {
        position: absolute;
        top: 20%;
        right: 20px;
      }

      .control-buttons {
        display: flex;
        align-items: center;
        // flex-direction: column;
        gap: 10px;

        .voice-btn {
          min-width: 50px !important;
          height: 50px !important;
          background: linear-gradient(90deg, #ff0000 0%, #d60000 100%);
          border-radius: 50% !important;
          border: none !important;

          svg {
            width: 1.5rem;
            height: 1.5rem;
          }

          .mute-svg {
            width: 2.5rem;
          }
        }

        .btn {
          width: fit-content;
          background-color: #000;
          color: #fff;
          border: 1px solid #fff;
          font-size: 12px;
          border-radius: 0px;
          padding: 5px;
          min-width: 120px;
          height: 40px;
          border-radius: 10px;
        }
      }

      .name-btn-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .name {
          padding-left: 5px;
          font-size: 14px;
          font-weight: 600;
          word-wrap: break-word;
          margin-bottom: 0px;

          overflow: hidden;
          width: 80%;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          word-wrap: break-word;
        }

        .btn {
          background-color: transparent !important;
          border-color: transparent !important;

          .btn-primary {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.05);
            border: none;
            color: #000000;
            padding: 1px 10px 5px 10px;
            border-radius: 18px;
            font-size: 20px;

            span {
              margin-right: 5px;
            }
          }

          // width: 70px;
          span {
            font-size: 15px;
            margin-left: 2px;
          }
        }

        .btn:hover {
          background-color: rgba(107, 107, 107, 0.05);
        }
      }
    }
  }

  // Black table
  #VideosCollection {
    padding: 20px 10px 10px 10px;

    .grid-system {
      display: grid;
      grid-template-columns: auto auto auto auto auto auto auto auto;
      grid-gap: 10px;
    }

    .f-container {
      overflow-x: auto;
      overflow-y: visible !important;
      // .Scroller-x{
      //   min-width: 170% !important;
      // }

      .collection-outer-body {
        // width: 100%;
        // max-width: 1000px;
        // min-width: 100%;

        .assets-table-body {
          background-color: rgb(0, 0, 0);
          border-radius: 5px;
          color: #fff;
          font-size: 14px !important;

          .btn-danger {
            border-radius: 1.5px;
          }

          .col-lg-4,
          .col-lg-8 {
            padding: 0px;
          }

          .form-control {
            margin-bottom: 0px;
            background-color: #000;
            color: #fff !important;
            // border: none;
            box-shadow: none;
            font-size: 14px;
            margin-right: 10px;
          }

          .fa-angle-down {
            position: absolute;
            top: 11px;
            right: 20px;
          }

          label {
            display: flex;
            align-items: center;
            gap: 5px;
            // font-size: 14px;
          }

          .table-row .table-data p {
            color: #fff;
            border: 1px solid #dee2e6;
            padding: 6px 10px;
            border-radius: 3px;
            width: 100%;
            margin-right: 10px;
          }

          .doted-box {
            overflow: hidden;

            // margin-right: 10px;
            p {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              text-wrap: nowrap;
            }
          }
        }
      }
    }

    .Dropdown_box {
      .dropdown-toggle {
        background-color: transparent;
        color: #000000;
        border: none;
      }

      .dropdown-menu.show {
        background-color: #262626;
        box-shadow: 1px 3px 4px 1px #787878;

        border-radius: 0px;
        border-top: 3px solid #d9251d;
      }

      .dropdown-item {
        background-color: transparent;
        color: #ffffff;
        font-size: 14px;
      }

      .dropdown-item:hover {
        background-color: #50505080;
      }

      .dropdown-toggle::after {
        display: none;
      }
    }
  }
}

// Login_Page
.Login_Page {
  .card {
    border: none;
    box-shadow: 2px 2px 10px 1px rgba(28, 28, 28, 0.4);
    margin: 40px auto;
    padding: 20px 20px 50px 20px;
    width: 90%;
    max-width: 430px;
    // min-height: 50vh;

    h2 {
      text-align: center;
    }

    .logo_input {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      width: 150px;
      margin-bottom: 20px;
    }

    .sm-text {
      font-size: 14px;
      color: #2f2b3dc7;
      margin-bottom: 0px;
      text-align: center;
    }

    form {
      .Form-group {
        position: relative;

        .eye-icon {
          position: absolute;
          right: 5px;
          top: 52px;
          background-color: transparent !important;
          border: transparent;
          color: #000 !important;
        }
      }

      .form-label {
        text-align: left;
        padding: 20px 0px 5px 0px;
        margin-bottom: 0px;
      }

      .form-control {
        box-shadow: none;
        color: #999999;
        border-radius: 3px;
        margin-bottom: 0px;
        font-size: 14px;
        padding: 10px;

        &::placeholder {
          color: #999999;
        }
      }
    }

    a {
      text-decoration: none;
      color: rgb(6, 88, 194);
      font-weight: 600;
      font-size: 14px;
    }

    .btn-danger {
      border-radius: 3px;
      font-weight: 600;
      border: none;
      padding: 10px 8px;
      font-size: 14px;
      margin: 20px 0px;
      width: 100%;
    }

    .btn-danger:hover {
      background-color: #e7382e;
    }

    .lines {
      display: flex;
      align-items: center;
      margin: 5px;
      justify-content: space-between;

      .text {
        padding: 0px 2px;
        // border: 1px solid #33333379;

        h6 {
          margin-bottom: 0px;
          color: rgb(0, 0, 0);
          font-size: 12px;
        }
      }

      .hr {
        color: #333333;
        width: 100%;
      }
    }

    .Googles-btn {
      display: flex;
      justify-content: center;
      padding: 20px 0px;
      gap: 8px;

      .btn {
        background-color: transparent;
        color: #d9251d;
        border: 1px solid #d9251d !important;
        border-radius: 2px !important;
        box-shadow: none;

        &:hover {
          background-color: #d9251d;
          color: #ffffff;
        }
      }
    }

    .New-account {
      display: flex;
      justify-content: center;
      // padding-top: 20px;
      font-size: 14px;
    }
  }
}

.content-body {
  padding: 20px;
}

.library-tabs {
  .tab-content {
    padding: 20px 0px;

    .form-label {
      color: rgba(10, 10, 10);
      font-size: 15px;
      font-weight: 600;
    }

    .btn-danger {
      margin-right: 5px;
      padding: 8px 12px !important;
      width: 129px;
      border-radius: 3px;
      z-index: 0;
    }

    .btn-danger:hover {
      background-color: rgba(212, 212, 212, 1);
    }
  }

  .nav-item {
    .nav-link {
      border: none;
      color: rgba(153, 153, 153, 1);
      font-weight: 600;
      font-size: 16px;
    }

    .nav-link.active {
      border: none;
      border-bottom: 3px solid #c07f00 !important;
      font-weight: 600;
      border-radius: 0px;
    }
  }

  .form_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin: 70px 0px 100px 0px;

    .card {
      width: 100% !important;
      margin: auto;
      padding: 30px;
      border: 0.06rem solid #c8c8d5;
      min-height: 200px;

      h3 {
        color: #000;
        font-size: 24px;
        text-align: center;
      }

      p {
        font-size: 14px;
        margin-bottom: 30px;
      }

      .uploader-drag-drop {
        .guZdik {
          border: 1px solid #ffffff;
          background-color: #ffffff;
          border-radius: 8px;
          margin-top: 0px;

          .kFhUBM {
            color: #fff !important;
            font-size: 10px;
          }

          .jWkLDY .file-types {
            color: #fff !important;
            font-size: 10px;
          }
        }
      }
    }
  }

  .uploader-drag-drop {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    .guZdik {
      // margin-top: 20px;
      // border: none;
    }
  }
}

.form-control {
  color: #999999 !important;
  border-radius: 3px !important;

  &::placeholder {
    color: #999999 !important;
  }
}

.infinite-scroll-component__outerdiv {
  .infinite-scroll-component {
    .row {
      .col-lg-4 {
        margin-bottom: 20px;

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 5px;
          // max-height: 500px;
        }
      }
    }
  }
}

.css-1gsv261 {
  border-bottom: none !important;
}

.Nav-link {
  color: #ffffff !important;
  font-weight: 500;
  box-shadow: none;
  font-size: 16px;
  text-decoration: none;
  border: none;
}

.assets-grid-card {
  box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;

  .banner {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px;

    h2 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 220px;
      margin-bottom: 0px;
      font-size: 22px !important;
    }
  }
}

.create-course-sibebar {
  video {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    max-height: 700px;
    object-fit: cover;
  }

  .Create-box {
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin: auto;
    border-radius: 3px;
    padding: 10px 0px;

    h5 {
      font-weight: 600;
    }

    .form-control {
      border-radius: 5px;
      margin-bottom: 0px;
      box-shadow: none;
      outline: none !important;
    }
  }

  .col-md-3,
  .col-md-2 {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .checkbox-flex-box {
    display: flex;
    flex-direction: column;

    label {
      font-size: 14px;
      display: flex;
      justify-content: space-between;
    }
  }

  .btn-box {
    .col-md-12 {
      display: flex;
      align-items: center;
    }

    .btn-secondary {
      background-color: #e6e6e6;
      border: none;
      color: #000;
      font-weight: 600;
    }

    .btn-dark {
      background-color: #000;
      border: none;
      color: #e6e6e6;
    }
  }
}

.error-div {
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .error_image {
    width: 500px;
  }

  h1 {
    margin-bottom: 30px;
  }

  .btn-danger {
    font-size: 20px !important;
    font-weight: 500;
    width: 200px;
    height: 50px;
  }
}

/* CSS animation for copied text */
@keyframes copiedTextAnimation {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  25% {
    transform: translateY(-10px);
    opacity: 1;
  }

  50% {
    transform: translateY(-20px);
    opacity: 0.75;
  }

  75% {
    transform: translateY(-30px);
    opacity: 0.5;
  }

  100% {
    transform: translateY(-40px);
    opacity: 0;
  }
}

/* Apply animation to a class when text is copied */
.copied {
  animation: copiedTextAnimation 1.5s ease-out;
}

// Sidebar
.lhYbII,
.kNwWZf,
.lpntlX {
  background-color: #fff !important;
  // border-right: 10px solid #f1f1f1 !important;
  min-height: 70vh !important;
  z-index: 0 !important;
  min-width: 250px !important;
  width: 250px !important;
  box-shadow: 0 0 15px 5px rgba(132, 132, 132, 0.1) !important;
}

.bBCvMk,
.sc-fjvvzt,
.bBCvMk {
  position: fixed;
  min-height: 100vh !important;
  max-height: 100vh !important;
}

.lhYbII.toggled,
.kNwWZf.toggled,
.lpntlX.toggled {
  width: 80px !important;
  min-width: 80px !important;
}

.ebQXSx a,
.jymIMH a,
.kIlCgy a {
  color: #000 !important;
  cursor: pointer !important;
  font-weight: 400 !important;

  svg {
    font-weight: 500;
  }

  &:hover {
    color: #ff0000 !important;
  }
}

.sidebar-imgs {
  width: 80px;
  height: 40px;
}

.fa-bars:before,
.fa-navicon:before {
  color: #000;
  cursor: pointer !important;
}

.jGeaPm .head-div,
.cpMOEy .head-div,
.jGjQoY .head-div {
  display: block !important;
  // align-items: center !important;
  // justify-content: space-between !important;
  // padding: 24px 20px 24px 10px !important;
  padding-left: 15px !important;
}

.icon-suffix {
  background: #f0f0f1 !important;
  border-radius: 50px !important;
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  svg,
  .fa {
    font-size: 20px !important;
  }
}

.pro-sidebar-footer {
  // display: none !important;
}

// Pagination
.collection-cards-main-wrapper {
  width: 100%;
}

.SetData-Btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .active {
    background-color: #c07f00;
    color: white;
  }

  button {
    border: 1px solid lightgray;
    color: #303030;
    border-radius: 4px;
    background-color: white;
    padding: 5px 15px 5px 15px;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 0.0178571429em;
    font-weight: 500;
    margin-left: 7px;
    // border-radius: 2px;
    text-transform: none;
  }
}

.span-14 {
  border-radius: 4px;
  border: 1px solid lightgray;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  gap: 0px;
  padding: 0px 10px 0px 10px;
}

.div-39 {
  color: var(--Primary-text, #2d2f31);
  padding: 2px 10px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 14px/143% "Montserrat", sans-serif, sans-serif;
  border: none;
}

@media (max-width: 991px) {
  .div-39 {
    white-space: initial;
  }
}



.no-data-found {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 300px;
  }
}

.no-data-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 300px;
  }
}

.img-modal-btn {
  background: transparent !important;
  padding: 0px !important;
  border: none !important;
  box-shadow: none !important;
}

.img-hover-modal {
  .modal-dialog {
    height: 100vh !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .modal-content {
    background-color: transparent;
    width: fit-content !important;
    border: none !important;
  }

  .modal-body {
    padding: 0px !important;
    background-color: transparent !important;

    img {
      max-width: 400px;
      max-height: 400px;
      border-radius: 6px;
    }
  }
}

.modal-backdrop {
  background-color: transparent !important;
}

.red-bar {
  left: 15px;
}

.black-bar {
  // left: 16px;
  width: 97.55%;
  left: 1.25%;
}

.black-bar-safari {
  left: 163px;
  width: 77.5%;
}

@media (max-width: 800px) {
  .black-bar-safari {
    width: 93%;
  }
}

.tooltip-container-video {
  position: relative;
}

.tooltip-video {
  position: absolute;
  top: 50px;
  /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  opacity: 1;
  pointer-events: none;
  transition: opacity 0.3s ease;

  .flex-tooltip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    .icon-flex-box {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
}

// .tooltip-container-video:hover .tooltip-video {
//   opacity: 1;
// }

// .video-container:hover .tooltip-video{
//   opacity: 1;
// }

.normal-repeat-icon {
  color: #000;
  width: 1em;
  height: 1em;
}

.repeated-repeat-icon {
  color: #eb0000;
  width: 1em;
  height: 1em;
}

.words-around-mic {
  position: absolute;
  top: -20%;
  left: -18%;
  width: 130% !important;
  height: 130% !important;
  fill: white !important;
}

.views-by-name-side {
  margin-left: 20px;
}

.views-eye-container {
  display: inline-block;
  margin: auto;
}

.performers-page {
  height: 100%;
  padding: 50px;

  &>.performers-list-container>* {
    cursor: default;
  }

  & .performer-dropdown-row {
    margin: 10px auto;
  }

  & .update-performer-name-input {
    display: inline-block;
  }

  & .performer-data-form {
    &>div {
      display: flex;
      gap: 0.5rem;
      flex-wrap: nowrap;
    }
  }

  & .create-performer {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    &>input {
      height: 50px;
      width: 90%;
      padding: 10px;
      box-shadow: none;
      color: #999999;
      border-radius: 3px;
      margin-bottom: 0px;
      font-size: 14px;
      padding: 10px;

      &::placeholder {
        color: #999999;
      }
    }

    &>textarea {
      width: 90%;
      outline: none !important;
      border: 1px solid #ddd;
      color: #999999;
      border-radius: 3px;
      margin-left: -7px;
      padding: 5px 10px;

      &::placeholder {
        color: #999999;
        font-size: 14px;
      }
    }

    &>button {
      width: 90%;
      background: #c07f00;

      &:hover {
        background: #f0a001;
      }
    }
  }
}

.active {
  background-color: #c07f00;
  color: white;
}

.loading-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 5px;
}

.dashboard_content_box {
  overflow-x: auto;
  height: 100vh;

  .dashboard_content_flex {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    min-height: 100vh;
  }
}

.sidebar_pagination{
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  > select{
    background-color: #f0f0f1 !important;
    border: 1px solid #f0f0f1;
    width: 70%;
  }
}