.video-container {
    position: relative;
    /* width: 640px; */
    /* height: 360px; */
    width: 100%;
}

.thumbnail-container {
    position: absolute;
    bottom: 50px;
    left: 0;
    display: none;
    width: 160px;
    height: 90px;
    border: 2px solid #fff;
    background-color: rgba(0, 0, 0, 0.75);
}

canvas {
    width: 100%;
    height: 100%;
}


/* .video-container {
    position: relative;
    width: 640px;
    height: 360px;
}

.thumbnail-container {
    position: absolute;
    bottom: 50px;
    left: 0;
    display: none;
    width: 160px;
    height: 90px;
    border: 2px solid #fff;
    background-color: rgba(0, 0, 0, 0.75);
}

canvas {
    width: 100%;
    height: 100%;
} */
