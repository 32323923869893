@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap");



//********************************* Global SCSS *************************

$manrope: 'Manrope', sans-serif;





.collection-cards-main-wrapper {
  width: 100%;
}

.SetData-Btn {
  display: flex;
  // margin-top: 15px;
  justify-content: flex-end;
  align-items: center;

  .active {
    // background-color: #201658;
    // background: linear-gradient(124deg, #CD9A27 50%, #bb7b28 64%);
    background-color: #C07F00;
    color: white;
  }

  button {
    border: 1px solid lightgray;
    color: #303030;
    border-radius: 4px;
    background-color: white;
    padding: 5px 15px 5px 15px;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 0.0178571429em;
    font-weight: 500;
    margin-left: 7px;
    // border-radius: 2px;
    text-transform: none;
  }
}

.span-14 {
  border-radius: 4px;
  border: 1px solid lightgray;
  /* border: 1px solid var(--line, #dee1e6); */
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  gap: 0px;
  padding: 0px 10px 0px 10px;
}

.div-39 {
  color: var(--Primary-text, #2d2f31);
  /* text-align: center; */
  padding: 2px 10px;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 14px/143% Poppins, sans-serif;
  border: none;
}

@media (max-width: 991px) {
  .div-39 {
    white-space: initial;
  }
}

.no-data-found{
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        width: 300px;
    }
}

.no-data-outer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img{
        width: 300px;
    }
}