body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* CSS animation for copied text */
@keyframes copiedTextAnimation {
  0% { transform: translateY(0); opacity: 1; }
  25% { transform: translateY(-10px); opacity: 1; }
  50% { transform: translateY(-20px); opacity: 0.75; }
  75% { transform: translateY(-30px); opacity: 0.5; }
  100% { transform: translateY(-40px); opacity: 0; }
}

/* Apply animation to a class when text is copied */
.copied {
  animation: copiedTextAnimation 1.5s ease-out;
}


/* CSS for copy notification */
#copyNotification {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  animation: fadeInOut 2s ease-in-out;
  display: none; /* initially hidden */
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
}
